import { render, staticRenderFns } from "./MobileSearchResults.vue?vue&type=template&id=2b58fbc4&"
import script from "./MobileSearchResults.vue?vue&type=script&lang=js&"
export * from "./MobileSearchResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchProductCard: require('/vercel/path0/components/search/SearchProductCard.vue').default,ArrowLeftLight: require('/vercel/path0/components/icons/ArrowLeftLight.vue').default,ArrowLeft: require('/vercel/path0/components/icons/ArrowLeft.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,MobileNavigationWrapper: require('/vercel/path0/components/navigation/MobileNavigationWrapper.vue').default})
