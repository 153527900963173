export default {
  methods: {
    getCoverImage(product, use2d = false) {
      let coverImage = null
      if (use2d) {
        coverImage = product?.cover_image_2d ?? product?.image
      } else {
        coverImage = product?.cover_image ?? product?.image
      }

      if (typeof coverImage === 'string') {
        return {
          permalink: coverImage,
          alt: `${product.title} Bild`,
        }
      }

      if (
        coverImage?.permalink?.endsWith('.psd') ||
        coverImage?.permalink?.endsWith('.tif')
      ) {
        return null
      }

      return coverImage
    },
  },
}
