
export default {
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },

    isNegative: {
      type: Boolean,
      default: false,
    },

    isHover: {
      type: Boolean,
      default: false,
    },

    isGreen: {
      type: Boolean,
      default: false,
    },
  },
}
