
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },
  },
}
