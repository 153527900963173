export const state = () => ({})

export const actions = {
  async subscribe({ commit }, payload) {
    await this.$axios.post('/components/newsletter-form', payload)
  },
}

export const mutations = {}

export const getters = {}
