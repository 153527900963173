export const state = () => ({
  order: null,
})

export const actions = {
  async all({ commit }, limit = 15) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$get(
      `/users/${id}/orders?limit=${limit}`
    )
    return data
  },

  async get({ commit }, orderId) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$get(`/users/${id}/orders/${orderId}`)
    commit('setOrder', data)
    return data
  },
}

export const mutations = {
  setOrder(state, order) {
    state.order = order
  },
}

export const getters = {}
