import { render, staticRenderFns } from "./PageFooter.vue?vue&type=template&id=2ce2bba8&"
import script from "./PageFooter.vue?vue&type=script&lang=js&"
export * from "./PageFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HepLogo: require('/vercel/path0/components/general/HepLogo.vue').default,Address: require('/vercel/path0/components/profile/Address.vue').default,NavLink: require('/vercel/path0/components/navigation/NavLink.vue').default,ArrowLink: require('/vercel/path0/components/general/ArrowLink.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
