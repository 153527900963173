
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('global', ['navs']),
    ...mapGetters('search', ['productResults', 'pageResults', 'query']),

    searchPath() {
      return this.localePath({
        name: 'suche',
        query: { searchQuery: this.query },
      })
    },
  },
}
