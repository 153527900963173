
import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeSubnav: null,
    }
  },

  computed: {
    ...mapState('global', ['navs', 'mainNavSettings']),
  },

  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },

    openSubnav(item) {
      this.activeSubnav = item
    },

    closeSubnav() {
      this.activeSubnav = null
    },
  },
}
