export const state = () => ({
  coupon: null,
})

export const actions = {
  async getAllOwned({ commit }, page = 1) {
    const params = new URLSearchParams({ page })
    const data = await this.$axios.$get(
      `/users/${this.$auth.user.id}/product-coupons?${params.toString()}`
    )

    return data
  },

  async getOwned({ commit }, productId) {
    const { data } = await this.$axios.$get(
      `/users/${this.$auth.user.id}/product-coupons/${productId}`
    )

    return data
  },

  async getRedeemed({ commit }, couponCode) {
    const { data: coupon } = await this.$axios.$get(
      `/users/${this.$auth.user.id}/redeemed-coupons/${couponCode}`
    )

    return coupon
  },

  async getDetails({ commit }, couponCode) {
    if (!couponCode) return null

    const { data: coupon } = await this.$axios.$get(`/coupons/${couponCode}`)

    return coupon
  },

  async redeemCoupon({ commit }, { code, redeemForPlatform }) {
    const { data: coupon } = await this.$axios.$post(
      `/users/${this.$auth.user.id}/coupons/redeem`,
      {
        code,
        redeem_for_platform: redeemForPlatform,
      }
    )

    return coupon
  },
}

export const mutations = {
  setCoupon(state, coupon) {
    state.coupon = coupon
  },
}

export const getters = {}
