
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },

    isTop: {
      type: Boolean,
      default: false,
    },
  },
}
