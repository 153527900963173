export default {
  methods: {
    setFocusToInput(element) {
      element.focus()

      // ensure the cursor is at the end of the field
      // https://css-tricks.com/snippets/javascript/move-cursor-to-end-of-input/
      if (typeof element.selectionStart === 'number') {
        element.selectionStart = element.selectionEnd = element.value.length
      } else if (typeof element.createTextRange !== 'undefined') {
        element.focus()
        const range = element.createTextRange()
        range.collapse(false)
        range.select()
      }
    },
  },
}
