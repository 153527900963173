import jwtDecode from 'jwt-decode'

export const state = () => ({})

export const actions = {
  async login({ dispatch }, { email, password, linkGuestCart = false }) {
    await this.$auth.loginWith('local', {
      data: {
        username: email,
        password,
        client_secret: this.$config.passportClientSecret,
      },
    })

    if (linkGuestCart) {
      await dispatch('cart/linkGuestCartToUser', null, { root: true })
    }
  },

  async logout({ commit }) {
    await this.$auth.logout()

    commit('cart/setCart', null, { root: true })
  },

  async reload(_) {
    await this.$auth.fetchUser()
    return this.$auth.user
  },

  async update(_, user) {
    const { data } = await this.$axios.$put(`/users/${user.id}`, user)
    this.$auth.setUser(data)
    return data
  },

  async updatePassword(_, form) {
    await this.$axios.$put(`/auth/reset-password`, form)
  },

  async create(_, user) {
    const { data } = await this.$axios.$post(`/auth/register`, user)
    return data
  },

  async sendPasswordResetLink(_, email) {
    const { data } = await this.$axios.$post('auth/forgot-password', {
      email,
    })
    return data
  },

  async recoverPassword(_, form) {
    const { data } = await this.$axios.post('auth/recover-password', form)
    return data
  },

  async confirmEmail(_, { id, hash, signature, expires }) {
    const { data } = await this.$axios.$post(
      `auth/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`
    )
    return data
  },

  async resendVerificationMail() {
    const { data } = await this.$axios.$post('auth/resend-verification-email')
    return data
  },

  async register({ dispatch }, payload) {
    await dispatch('create', payload)
    await dispatch('login', {
      email: payload.email,
      password: payload.password,
    })
    await dispatch('cart/linkGuestCartToUser', null, { root: true })
  },
}

export const mutations = {}

export const getters = {
  currentUser(_, _2, rootState) {
    return rootState.auth.user ?? {}
  },

  decodedToken(_, _2, rootState) {
    let token = rootState.auth['hep.local']
    if (!token) {
      return null
    }

    token = token.replace('Bearer ')

    try {
      const decodedToken = jwtDecode(token)

      return decodedToken
    } catch (error) {
      this.$sentry.captureException(error)
      return null
    }
  },

  hasGroup: (_, getters) => (checkForGroup) => {
    const groups = getters.groups

    return groups.includes(checkForGroup)
  },

  hasGroups: (_, getters) => (requiredGroups) => {
    const currentGroups = getters.groups

    if (!requiredGroups?.length) {
      return true
    }

    return requiredGroups.some((group) => {
      return currentGroups.includes(group)
    })
  },

  isTeacher(_, getters) {
    return getters.hasGroups(['teacher', 'pending_teacher'])
  },

  isConfirmedTeacher(_, getters) {
    return getters.hasGroups(['teacher'])
  },

  groups(_, getters) {
    const decodedToken = getters.decodedToken
    return decodedToken?.groups ?? []
  },
}
