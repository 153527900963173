import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'

import {
  faFileCode,
  faPaperPlane,
  faCheckCircle,
  faInfoCircle,
  faBars,
  faTh,
  faUser,
  faSpinner as farSpinner,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import {
  faTelescope,
  faLockAlt,
  faSignIn,
  faExclamationCircle,
  faArrowLeft,
  faArrowRight,
  faShoppingCart,
  faUndo,
  faBook,
  faCartPlus,
  faChevronDown,
  faEnvelope,
  faTrash,
  faSignInAlt,
  faSpinner,
  faTimes,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faPlayCircle as farPlayCircle,
  faLink,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFileArchive,
  faFileImage,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faArrowCircleRight,
  faArrowCircleLeft,
  faLongArrowRight,
  faLongArrowLeft,
  faMinus,
  faPlus,
  faUserTie,
  faSortAlt,
  faSlidersV,
  faEnvelope as falEvenlope,
} from '@fortawesome/pro-light-svg-icons'

import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

config.autoAddCss = false

library.add(
  faTelescope,
  faLockAlt,
  faSignIn,
  faFileCode,
  faExclamationCircle,
  faPaperPlane,
  faSortAlt,
  faSlidersV,
  faShoppingCart,
  faUndo,
  faBook,
  faUserTie,
  faUser,
  faArrowLeft,
  faArrowRight,
  faBook,
  faCartPlus,
  faChevronDown,
  faEnvelope,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faLongArrowRight,
  faLongArrowLeft,
  faMinus,
  faPlus,
  faSignInAlt,
  faSpinner,
  faTrash,
  faTimes,
  faTwitter,
  faArrowCircleRight,
  faArrowCircleLeft,
  faPlayCircle,
  farPlayCircle,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faExclamationTriangle,
  faLink,
  faFileImage,
  faFileArchive,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faInfoCircle,
  faCheckCircle,
  faLink,
  faBars,
  faTh,
  falEvenlope,
  farSpinner
)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
