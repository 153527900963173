
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('global', ['mainNavSettings']),
  },

  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },

    isIconNavigation(item) {
      if (!Array.isArray(this.mainNavSettings.icon_navs)) {
        return false
      }

      return this.mainNavSettings.icon_navs.includes(item.page.title)
    },

    iconForPage(page) {
      if (!Array.isArray(this.mainNavSettings.nav_icons)) {
        return null
      }

      const navIconMappingForPage = this.mainNavSettings.nav_icons.find(
        (navIcon) => page?.id && navIcon.entry.id === page.id
      )

      return navIconMappingForPage?.icon.key
    },
  },
}
