export const state = () => ({
  shoppingLists: [],
  shoppingList: null,
})

export const actions = {
  async all({ commit }) {
    const { data } = await this.$axios.$get(`/shop/shopping-lists`)

    commit('setShoppingLists', data)

    return data
  },

  async get({ commit }, code) {
    const { data } = await this.$axios.$get(`/shop/shopping-lists/${code}`)

    commit('setShoppingList', data)

    return data
  },

  async getShared({ commit }, code) {
    const { data } = await this.$axios.$get(
      `/shop/shopping-lists/${code}/shared`
    )

    return data
  },

  async update({ commit }, shoppingList) {
    const { data } = await this.$axios.$put(
      `/shop/shopping-lists/${shoppingList.code}`,
      shoppingList
    )

    commit('setShoppingListName', data.name)

    return data
  },

  async deleteProduct({ commit, state }, payload) {
    const { data } = await this.$axios.$delete(
      `/shop/shopping-lists/${state.shoppingList.code}/products/${payload.product.id}`
    )

    commit('removeProductFromShoppingList', payload)

    return data
  },

  async delete({ dispatch }, shoppingList) {
    return await this.$axios.$delete(
      `/shop/shopping-lists/${shoppingList.code}`
    )
  },

  async attachProductToShoppingList({ commit }, { code, productId }) {
    const { data: shoppingList } = await this.$axios.$post(
      `/shop/shopping-lists/${code}/products`,
      {
        products: [productId],
      }
    )

    commit('replaceShoppingList', shoppingList)
  },

  async detachProductFromShoppingList({ commit }, { code, productId }) {
    const { data: shoppingList } = await this.$axios.$delete(
      `/shop/shopping-lists/${code}/products/${productId}`
    )

    commit('replaceShoppingList', shoppingList)
  },
}

export const mutations = {
  setShoppingLists(state, value) {
    state.shoppingLists = value
  },

  setShoppingList(state, value) {
    state.shoppingList = value
  },

  setShoppingListName(state, value) {
    state.shoppingList.name = value
  },

  addShoppingList(state, value) {
    state.shoppingLists.push(value)
  },

  replaceShoppingList(state, shoppingList) {
    const index = state.shoppingLists.findIndex(
      (s) => s.code === shoppingList.code
    )

    state.shoppingLists.splice(index, 1, shoppingList)
  },

  removeProductFromShoppingList(state, { product, index }) {
    state.shoppingList.products.splice(index, 1)
  },
}
