export const state = () => ({
  fixedNavData: {},
  windowHeight: 0.5,
})

export const mutations = {
  setFixedNavData(state, navData) {
    state.fixedNavData = navData
  },

  setWindowHeight(state, windowHeight) {
    state.windowHeight = windowHeight
  },
}

export const getters = {
  fixedNavData(state) {
    return state.fixedNavData
  },

  windowHeight(state) {
    return state.windowHeight
  },
}
