
import ProductFunctions from '~/mixins/ProductFunctions'

export default {
  mixins: [ProductFunctions],

  props: {
    product: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },
}
