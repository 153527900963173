export const state = () => ({
  departmentTaxonomies: [],
  articleCategoryTaxonomies: [],
})

export const actions = {
  async loadDepartmentsTaxonomy({ commit }) {
    try {
      const { data } = await this.$axios.$get(`/website/taxonomies/departments`)
      commit('setDepartmentTaxonomies', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadArticleCategoryTaxonomy({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        `/website/taxonomies/article_categories`
      )
      commit('setArticleCategoryTaxonomies', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
}

export const mutations = {
  setDepartmentTaxonomies(state, value) {
    state.departmentTaxonomies = value
  },

  setArticleCategoryTaxonomies(state, value) {
    state.articleCategoryTaxonomies = value
  },
}

export const getters = {}
