
export default {
  props: {
    to: {
      type: String,
      required: true,
    },

    isNegative: {
      type: Boolean,
      default: false,
    },

    isGray: {
      type: Boolean,
      default: false,
    },

    isHover: {
      required: false,
      type: Boolean,
    },

    blank: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      isInternalHover: false,
    }
  },
}
