import { render, staticRenderFns } from "./MobileNavigation.vue?vue&type=template&id=04b008f6&scoped=true&"
import script from "./MobileNavigation.vue?vue&type=script&lang=js&"
export * from "./MobileNavigation.vue?vue&type=script&lang=js&"
import style0 from "./MobileNavigation.vue?vue&type=style&index=0&id=04b008f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b008f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileSubnavigation: require('/vercel/path0/components/navigation/MobileSubnavigation.vue').default,NavLink: require('/vercel/path0/components/navigation/NavLink.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,MapMarker: require('/vercel/path0/components/icons/MapMarker.vue').default,PopoverButton: require('/vercel/path0/components/popover/PopoverButton.vue').default,TooltipBox: require('/vercel/path0/components/general/TooltipBox.vue').default,PopoverPanel: require('/vercel/path0/components/popover/PopoverPanel.vue').default,Popover: require('/vercel/path0/components/popover/Popover.vue').default,Profile: require('/vercel/path0/components/icons/Profile.vue').default,Lock: require('/vercel/path0/components/icons/Lock.vue').default,MobileNavigationWrapper: require('/vercel/path0/components/navigation/MobileNavigationWrapper.vue').default})
