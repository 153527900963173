import { render, staticRenderFns } from "./MainHeader.vue?vue&type=template&id=002094d3&scoped=true&"
import script from "./MainHeader.vue?vue&type=script&lang=js&"
export * from "./MainHeader.vue?vue&type=script&lang=js&"
import style0 from "./MainHeader.vue?vue&type=style&index=0&id=002094d3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002094d3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DesktopMetaNavigation: require('/vercel/path0/components/navigation/DesktopMetaNavigation.vue').default,DesktopMainNavigation: require('/vercel/path0/components/navigation/DesktopMainNavigation.vue').default,NavigationSearchInput: require('/vercel/path0/components/navigation/NavigationSearchInput.vue').default,Magnifier: require('/vercel/path0/components/icons/Magnifier.vue').default,RotatedPin: require('/vercel/path0/components/icons/RotatedPin.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,CartIndicator: require('/vercel/path0/components/shop/CartIndicator.vue').default,Cross: require('/vercel/path0/components/icons/Cross.vue').default,Burger: require('/vercel/path0/components/icons/Burger.vue').default,MapMarker: require('/vercel/path0/components/icons/MapMarker.vue').default,PopoverButton: require('/vercel/path0/components/popover/PopoverButton.vue').default,TooltipBox: require('/vercel/path0/components/general/TooltipBox.vue').default,PopoverPanel: require('/vercel/path0/components/popover/PopoverPanel.vue').default,Popover: require('/vercel/path0/components/popover/Popover.vue').default,Profile: require('/vercel/path0/components/icons/Profile.vue').default,Lock: require('/vercel/path0/components/icons/Lock.vue').default,ArrowLeftLight: require('/vercel/path0/components/icons/ArrowLeftLight.vue').default,SearchProductCard: require('/vercel/path0/components/search/SearchProductCard.vue').default,PopoverGroup: require('/vercel/path0/components/popover/PopoverGroup.vue').default,MobileNavigation: require('/vercel/path0/components/navigation/MobileNavigation.vue').default,MobileSearchResults: require('/vercel/path0/components/navigation/MobileSearchResults.vue').default,HeaderContainer: require('/vercel/path0/components/navigation/HeaderContainer.vue').default})
