
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('global', ['mainNavSettings']),
  },

  methods: {
    iconForPage(page) {
      if (!Array.isArray(this.mainNavSettings.nav_icons)) {
        return null
      }

      const navIconMappingForPage = this.mainNavSettings.nav_icons.find(
        (navIcon) => page?.uri && navIcon.entry.url === page.uri
      )

      return navIconMappingForPage?.icon.key
    },
  },
}
