// Example for form setup
export const state = () => ({
  redeemCouponPageSettings: null,
  loginPageSettings: null,
  registrationSettings: null,
  resetPasswordPageSettings: null,
  defaultProductComponentsSettings: null,

  navs: {},
  mainNavSettings: {},
  footerSettings: {},
})

export const actions = {
  async loadRedeemCouponPageSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/redeem_coupon_page_settings'
      )
      commit('setRedeemCouponPageSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async loadLoginPageSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/login_page_settings'
      )
      commit('setLoginPageSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadRegistrationSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/registration_settings'
      )
      commit('setRegistrationSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadResetPasswordPageSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/reset_password_page_settings'
      )
      commit('setResetPasswordPageSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadCheckoutPageSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/checkout_page_settings'
      )
      commit('setCheckoutPageSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadDefaultProductComponentsSettings({ commit }) {
    try {
      const { data } = await this.$axios.$get(
        '/cms/globals/default_product_components_settings'
      )
      commit('setDefaultProductComponentsSettings', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadNavigations({ commit }) {
    const [navs, mainNavSettings, footerSettings] = await Promise.all([
      this.$axios.$get('/website/navigations'),
      this.$axios.$get('/cms/globals/main_navigation_settings'),
      this.$axios.$get('/cms/globals/footer_settings'),
    ])

    if (!navs || !mainNavSettings || !footerSettings) {
      return
    }

    commit('setNavs', navs.data)
    commit('setMainNavSettings', mainNavSettings.data)
    commit('setFooterSettings', footerSettings.data)
  },
}

export const mutations = {
  setRedeemCouponPageSettings(state, value) {
    state.redeemCouponPageSettings = value
  },

  setLoginPageSettings(state, value) {
    state.loginPageSettings = value
  },

  setRegistrationSettings(state, value) {
    state.registrationSettings = value
  },

  setResetPasswordPageSettings(state, value) {
    state.resetPasswordPageSettings = value
  },

  setCheckoutPageSettings(state, value) {
    state.checkoutPageSettings = value
  },

  setDefaultProductComponentsSettings(state, value) {
    state.defaultProductComponentsSettings = value
  },

  setNavs(state, value) {
    state.navs = value
  },
  setMainNavSettings(state, value) {
    state.mainNavSettings = value
  },
  setFooterSettings(state, value) {
    state.footerSettings = value
  },
}

export const getters = {}
