
import { mapState } from 'vuex'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('global', ['mainNavSettings']),
  },

  methods: {
    isIconNavigation(item) {
      if (!Array.isArray(this.mainNavSettings.icon_navs)) {
        return false
      }

      return this.mainNavSettings.icon_navs.includes(item.page.title)
    },
  },
}
