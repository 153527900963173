
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('global', ['footer', 'navs', 'footerSettings']),
  },

  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },
  },
}
