import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b4f6348 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _3620c042 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _d49659f2 = () => interopDefault(import('../pages/checkout/coupon-platform.vue' /* webpackChunkName: "pages/checkout/coupon-platform" */))
const _e94bc776 = () => interopDefault(import('../pages/checkout/licences.vue' /* webpackChunkName: "pages/checkout/licences" */))
const _a2d17a14 = () => interopDefault(import('../pages/checkout/login.vue' /* webpackChunkName: "pages/checkout/login" */))
const _0ad1345a = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _0f4e0b73 = () => interopDefault(import('../pages/checkout/summary.vue' /* webpackChunkName: "pages/checkout/summary" */))
const _e8456e7c = () => interopDefault(import('../pages/checkout/_order/confirmation.vue' /* webpackChunkName: "pages/checkout/_order/confirmation" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _f0815c8e = () => interopDefault(import('../pages/login/forgot-password.vue' /* webpackChunkName: "pages/login/forgot-password" */))
const _13c28ba8 = () => interopDefault(import('../pages/login-as.vue' /* webpackChunkName: "pages/login-as" */))
const _fc4a92fa = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _030fa3a1 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _679fb844 = () => interopDefault(import('../pages/profile/coupons/index.vue' /* webpackChunkName: "pages/profile/coupons/index" */))
const _a771818e = () => interopDefault(import('../pages/profile/details/index.vue' /* webpackChunkName: "pages/profile/details/index" */))
const _85355d76 = () => interopDefault(import('../pages/profile/e-learning/index.vue' /* webpackChunkName: "pages/profile/e-learning/index" */))
const _f119ca90 = () => interopDefault(import('../pages/profile/newsletter/index.vue' /* webpackChunkName: "pages/profile/newsletter/index" */))
const _5b4a63e4 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _48f78b82 = () => interopDefault(import('../pages/profile/partner/index.vue' /* webpackChunkName: "pages/profile/partner/index" */))
const _8de97680 = () => interopDefault(import('../pages/profile/pdfs/index.vue' /* webpackChunkName: "pages/profile/pdfs/index" */))
const _3a8e76cc = () => interopDefault(import('../pages/profile/shopping-list/index.vue' /* webpackChunkName: "pages/profile/shopping-list/index" */))
const _e0df627e = () => interopDefault(import('../pages/profile/shopping-list/index/create.vue' /* webpackChunkName: "pages/profile/shopping-list/index/create" */))
const _cf6e0044 = () => interopDefault(import('../pages/profile/details/address/index.vue' /* webpackChunkName: "pages/profile/details/address/index" */))
const _481a2484 = () => interopDefault(import('../pages/profile/details/password.vue' /* webpackChunkName: "pages/profile/details/password" */))
const _daec0004 = () => interopDefault(import('../pages/profile/details/personal-data.vue' /* webpackChunkName: "pages/profile/details/personal-data" */))
const _cee972c0 = () => interopDefault(import('../pages/profile/details/address/create.vue' /* webpackChunkName: "pages/profile/details/address/create" */))
const _4de813c6 = () => interopDefault(import('../pages/profile/partner/clients/create.vue' /* webpackChunkName: "pages/profile/partner/clients/create" */))
const _6f3161c6 = () => interopDefault(import('../pages/profile/details/address/_id.vue' /* webpackChunkName: "pages/profile/details/address/_id" */))
const _726e1660 = () => interopDefault(import('../pages/profile/partner/clients/_id.vue' /* webpackChunkName: "pages/profile/partner/clients/_id" */))
const _7d86fbac = () => interopDefault(import('../pages/profile/coupons/_id.vue' /* webpackChunkName: "pages/profile/coupons/_id" */))
const _55afe168 = () => interopDefault(import('../pages/profile/orders/_id.vue' /* webpackChunkName: "pages/profile/orders/_id" */))
const _36f914d8 = () => interopDefault(import('../pages/profile/shopping-list/_code.vue' /* webpackChunkName: "pages/profile/shopping-list/_code" */))
const _513e2755 = () => interopDefault(import('../pages/profile/shopping-list/_code/edit.vue' /* webpackChunkName: "pages/profile/shopping-list/_code/edit" */))
const _0d5f3939 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _686030fc = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _4bae89fa = () => interopDefault(import('../pages/register/standard.vue' /* webpackChunkName: "pages/register/standard" */))
const _22e56f8c = () => interopDefault(import('../pages/register/teacher.vue' /* webpackChunkName: "pages/register/teacher" */))
const _70d4d26f = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _d9c1734a = () => interopDefault(import('../pages/suche/index.vue' /* webpackChunkName: "pages/suche/index" */))
const _597b2a5e = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _232c1918 = () => interopDefault(import('../pages/coupons/redeem.vue' /* webpackChunkName: "pages/coupons/redeem" */))
const _520712f7 = () => interopDefault(import('../pages/coupons/redeem/index.vue' /* webpackChunkName: "pages/coupons/redeem/index" */))
const _1baeb60e = () => interopDefault(import('../pages/coupons/redeem/login.vue' /* webpackChunkName: "pages/coupons/redeem/login" */))
const _8e5684a6 = () => interopDefault(import('../pages/coupons/redeem/_coupon/index.vue' /* webpackChunkName: "pages/coupons/redeem/_coupon/index" */))
const _47a18755 = () => interopDefault(import('../pages/coupons/redeem/_coupon/coupon-platform.vue' /* webpackChunkName: "pages/coupons/redeem/_coupon/coupon-platform" */))
const _3d72321a = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _ea6a840c = () => interopDefault(import('../pages/shopping-list/_code.vue' /* webpackChunkName: "pages/shopping-list/_code" */))
const _5022c88e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _6b4f6348,
    children: [{
      path: "",
      component: _3620c042,
      name: "checkout___de_CH"
    }, {
      path: "coupon-platform",
      component: _d49659f2,
      name: "checkout-coupon-platform___de_CH"
    }, {
      path: "licences",
      component: _e94bc776,
      name: "checkout-licences___de_CH"
    }, {
      path: "login",
      component: _a2d17a14,
      name: "checkout-login___de_CH"
    }, {
      path: "payment",
      component: _0ad1345a,
      name: "checkout-payment___de_CH"
    }, {
      path: "summary",
      component: _0f4e0b73,
      name: "checkout-summary___de_CH"
    }, {
      path: ":order/confirmation",
      component: _e8456e7c,
      name: "checkout-order-confirmation___de_CH"
    }]
  }, {
    path: "/login",
    component: _7d9c04c3,
    children: [{
      path: "",
      component: _5e96a5f4,
      name: "login___de_CH"
    }, {
      path: "forgot-password",
      component: _f0815c8e,
      name: "login-forgot-password___de_CH"
    }]
  }, {
    path: "/login-as",
    component: _13c28ba8,
    name: "login-as___de_CH"
  }, {
    path: "/profile",
    component: _fc4a92fa,
    name: "profile___de_CH",
    children: [{
      path: "account",
      component: _030fa3a1,
      name: "profile-account___de_CH"
    }, {
      path: "coupons",
      component: _679fb844,
      name: "profile-coupons___de_CH"
    }, {
      path: "details",
      component: _a771818e,
      name: "profile-details___de_CH"
    }, {
      path: "e-learning",
      component: _85355d76,
      name: "profile-e-learning___de_CH"
    }, {
      path: "newsletter",
      component: _f119ca90,
      name: "profile-newsletter___de_CH"
    }, {
      path: "orders",
      component: _5b4a63e4,
      name: "profile-orders___de_CH"
    }, {
      path: "partner",
      component: _48f78b82,
      name: "profile-partner___de_CH"
    }, {
      path: "pdfs",
      component: _8de97680,
      name: "profile-pdfs___de_CH"
    }, {
      path: "shopping-list",
      component: _3a8e76cc,
      name: "profile-shopping-list___de_CH",
      children: [{
        path: "create",
        component: _e0df627e,
        name: "profile-shopping-list-index-create___de_CH"
      }]
    }, {
      path: "details/address",
      component: _cf6e0044,
      name: "profile-details-address___de_CH"
    }, {
      path: "details/password",
      component: _481a2484,
      name: "profile-details-password___de_CH"
    }, {
      path: "details/personal-data",
      component: _daec0004,
      name: "profile-details-personal-data___de_CH"
    }, {
      path: "details/address/create",
      component: _cee972c0,
      name: "profile-details-address-create___de_CH"
    }, {
      path: "partner/clients/create",
      component: _4de813c6,
      name: "profile-partner-clients-create___de_CH"
    }, {
      path: "details/address/:id",
      component: _6f3161c6,
      name: "profile-details-address-id___de_CH"
    }, {
      path: "partner/clients/:id?",
      component: _726e1660,
      name: "profile-partner-clients-id___de_CH"
    }, {
      path: "coupons/:id",
      component: _7d86fbac,
      name: "profile-coupons-id___de_CH"
    }, {
      path: "orders/:id",
      component: _55afe168,
      name: "profile-orders-id___de_CH"
    }, {
      path: "shopping-list/:code?",
      component: _36f914d8,
      name: "profile-shopping-list-code___de_CH",
      children: [{
        path: "edit",
        component: _513e2755,
        name: "profile-shopping-list-code-edit___de_CH"
      }]
    }]
  }, {
    path: "/register",
    component: _0d5f3939,
    children: [{
      path: "",
      component: _686030fc,
      name: "register___de_CH"
    }, {
      path: "standard",
      component: _4bae89fa,
      name: "register-standard___de_CH"
    }, {
      path: "teacher",
      component: _22e56f8c,
      name: "register-teacher___de_CH"
    }]
  }, {
    path: "/reset-password",
    component: _70d4d26f,
    name: "reset-password___de_CH"
  }, {
    path: "/suche",
    component: _d9c1734a,
    name: "suche___de_CH"
  }, {
    path: "/verify-email",
    component: _597b2a5e,
    name: "verify-email___de_CH"
  }, {
    path: "/coupons/redeem",
    component: _232c1918,
    children: [{
      path: "",
      component: _520712f7,
      name: "coupons-redeem___de_CH"
    }, {
      path: "login",
      component: _1baeb60e,
      name: "coupons-redeem-login___de_CH"
    }, {
      path: ":coupon",
      component: _8e5684a6,
      name: "coupons-redeem-coupon___de_CH"
    }, {
      path: ":coupon/coupon-platform",
      component: _47a18755,
      name: "coupons-redeem-coupon-coupon-platform___de_CH"
    }]
  }, {
    path: "/shop/cart",
    component: _3d72321a,
    name: "shop-cart___de_CH"
  }, {
    path: "/shopping-list/:code?",
    component: _ea6a840c,
    name: "shopping-list-code___de_CH"
  }, {
    path: "/*",
    component: _5022c88e,
    name: "all___de_CH"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
