
import debounce from 'lodash/debounce'
import InputFocusHelper from '~/mixins/InputFocusHelper'

export default {
  mixins: [InputFocusHelper],

  data() {
    return {
      innerValue: '',
    }
  },

  computed: {
    hideCloseOnSearchPage() {
      return this.$route.path !== '/suche'
    },
  },

  watch: {
    innerValue(innerValue) {
      this.updateSearchQueryParam(innerValue)
      this.search(innerValue)
    },

    $route() {
      this.setFocusOnPageEnter()

      if (this.$route.path !== '/suche') {
        this.innerValue = ''
      } else {
        // q parameter is set by the old search page which is redirected
        this.innerValue = this.$route.query?.searchQuery || this.$route.query?.q
      }
    },
  },

  created() {
    // q parameter is set by the old search page which is redirected
    this.innerValue = this.$route.query.searchQuery || this.$route.query.q
  },

  mounted() {
    this.setFocusOnPageEnter()
  },

  methods: {
    setFocus(value) {
      this.$emit('input-focus', value)
    },

    focus() {
      this.setFocusToInput(this.$refs.searchInput)
    },

    search: debounce(function (searchValue) {
      this.$store.dispatch('search/searchIndexes', searchValue)
    }, 300),

    setFocusOnPageEnter() {
      if (this.$route.path === '/suche') {
        this.focus()
      }
    },

    updateSearchQueryParam(value) {
      const params = { ...this.$route.query }
      // q parameter is set by the old search page which is redirected
      delete params.q
      if (!value || this.$route.path !== '/suche') {
        delete params.searchQuery
      } else {
        params.searchQuery = value
      }
      this.$router.push({ query: params, hash: this.$route.hash })
    },

    gotoSearchPage(event) {
      event.target.blur()
      this.$router.push({
        path: '/suche',
        query: {
          ...this.$route.query,
          searchQuery: event.target.value,
        },
      })
    },
  },
}
