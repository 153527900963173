export function requireAuth(options) {
  const { redirectTo } = {
    redirectTo: null,

    ...options,
  }

  return ({ $auth, redirect, error, i18n }) => {
    if (!$auth.loggedIn) {
      if (redirectTo || $auth.options.redirect.login) {
        redirect(redirectTo || $auth.options.redirect.login)
      } else {
        error({
          statusCode: 403,
          message: i18n.t('unauthenticated'),
        })
      }
    }
  }
}

export function requireGuest(options) {
  const { redirectTo } = {
    redirectTo: null,

    ...options,
  }

  return ({ $auth, redirect }) => {
    if ($auth.loggedIn) {
      if (redirectTo || $auth.options.redirect.home) {
        redirect(redirectTo || $auth.options.redirect.home)
      }
    }
  }
}
