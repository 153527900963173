export function useDispatchStoreAction(config) {
  const { action, name, observerRef, successTextKey, errorTextKey } = {
    name: 'storeAction',
    observerRef: 'observer',
    successTextKey: 'updated-successfully',
    errorTextKey: 'error',

    ...config,
  }

  const nameUcFirst = name.charAt(0).toUpperCase() + name.slice(1)

  return {
    data() {
      return {
        [name]: { state: 'idle' },
      }
    },

    methods: {
      [`dispatch${nameUcFirst}`](payload, options) {
        const { silentOnSuccess, silentOnError, observer } = {
          silentOnSuccess: false,
          silentOnError: false,
          observer: this.$refs[observerRef],

          ...options,
        }

        return new Promise((resolve, reject) => {
          this[name].state = 'loading'

          this.$store
            .dispatch(action, payload)
            .then((response) => {
              if (!silentOnSuccess) {
                this.$toast.success(this.$t(successTextKey))
              }

              resolve(response)
            })
            .catch((e) => {
              if (
                e.response?.status === 422 &&
                e.response.data?.errors &&
                observer
              ) {
                observer.setErrors(e.response.data.errors)
              } else if (!silentOnError) {
                this.$toast.error(this.$t(errorTextKey))
              }

              reject(e)
            })
            .finally((_) => {
              this[name].state = 'idle'
            })
        })
      },
    },
  }
}
