import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { messages } from 'vee-validate/dist/locale/de.json'
import {
  required,
  confirmed,
  min,
  max,
  email,
  numeric,
} from 'vee-validate/dist/rules'

// ensure `components/componentloader/FormBuilder.vue is in sync with these rules to ensure proper client validation.
extend('required', { ...required, message: messages.required })
extend('confirmed', { ...confirmed, message: messages.confirmed })
extend('min', { ...min, message: messages.min })
extend('max', { ...max, message: messages.max })
extend('email', { ...email, message: messages.email })
extend('numeric', { ...numeric, message: messages.numeric })
extend('url', {
  validate: (value) => {
    if (value) {
      return /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/.test(
        value
      )
    }

    return false
  },
  message: '{_field_} muss ein gültiger URL sein',
})
extend('nodigits', {
  validate: (value) => {
    if (value) {
      return /^\D+$/.test(value)
    }

    return false
  },
  message: '{_field_} darf nur Buchstaben und Sonderzeichen enthalten',
})
extend('phone', {
  validate: (value) => {
    return /^(\+41|0|0041)[0-9]{9}$/.test(value.replace(/\s/g, ''))
  },
  message: '{_field_} muss eine gültige Telefonnummer sein',
})
extend('coupon', {
  validate: (value) => {
    return /^[0-9a-zA-Z]{12}$/.test(value.replace(/\s/g, ''))
  },
  message: '{_field_} muss eine gültiger Coupon sein',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
