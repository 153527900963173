
import { mapState, mapGetters } from 'vuex'
import { useDispatchStoreAction } from '~/mixins/DispatchStoreAction'

export default {
  mixins: [
    useDispatchStoreAction({
      name: 'resendVerificationEmail',
      action: 'user/resendVerificationMail',
    }),
  ],

  data() {
    return {
      mobileState: 'closed', // closed, nav, search
      inputFocus: false,

      isHoverPageResultsLink: false,
      isHoverProductResultsLink: false,

      verificationToast: null,
    }
  },

  computed: {
    ...mapState('global', ['navs', 'mainNavSettings']),
    ...mapGetters('search', ['productResults', 'pageResults', 'query']),
    user() {
      return this.$store.getters['user/currentUser']
    },

    isMobileNavOpen() {
      return this.mobileState === 'nav'
    },

    isMobileSearchOpen() {
      if (this.$route.path === '/suche') {
        return true // always open search on search page
      }

      return this.mobileState === 'search'
    },

    showMobileSearchResults() {
      return (
        this.mobileState === 'search' &&
        this.hasResults &&
        this.$route.path !== '/suche'
      )
    },

    shouldShowResultPopout() {
      return this.inputFocus && this.hasResults && this.$route.path !== '/suche'
    },

    searchPath() {
      return this.localePath({
        name: 'suche',
        query: { searchQuery: this.query },
      })
    },

    hasResults() {
      return this.productResults?.length > 0 || this.pageResults?.length > 0
    },
  },

  watch: {
    $route() {
      this.mobileState = 'closed'
      this.showEmailVerificationToast()
    },
  },

  mounted() {
    this.showEmailVerificationToast()
  },

  methods: {
    toggleMobileNav() {
      if (this.isMobileNavOpen) {
        this.mobileState = 'closed'
      } else {
        this.mobileState = 'nav'
      }
    },

    toggleMobileSearch() {
      if (this.isMobileSearchOpen) {
        this.mobileState = 'closed'

        this.$nextTick(() => {
          if (this.$route.path !== '/suche') {
            this.$refs.mobileSearchInput?.reset()
          }
        })
      } else {
        this.mobileState = 'search'

        this.$nextTick(() => {
          this.$refs.mobileSearchInput?.focus()
        })
      }
    },

    setInputFocus(value) {
      this.inputFocus = value
    },

    showEmailVerificationToast() {
      if (!this.$auth.loggedIn || this.user.is_email_verified) {
        this.verificationToast?.goAway(0)
        return
      }

      if (
        this.$route.path === '/profile/account' ||
        this.$route.path === '/profile/details' ||
        this.$route.path === '/verify-email'
      ) {
        this.verificationToast?.goAway(0)
      } else {
        if (this.verificationToast) {
          this.verificationToast.goAway(0)
        }
        this.verificationToast = this.$toasted.show(
          this.$t('profile-verify-email-note'),
          {
            className: 'verify-email',
            duration: 0,
            action: {
              text: this.$t('profile-verify-email-resend'),
              onClick: (e, toastObject) => {
                this.dispatchResendVerificationEmail(null, {
                  silentOnSuccess: true,
                  silentOnError: true,
                })
                  .then(() => {
                    this.$toast.success(
                      this.$t('profile-verify-email-sent', {
                        email: this.user.email,
                      }),
                      {
                        duration: 7000,
                      }
                    )
                  })
                  .catch((error) => {
                    this.$sentry.captureException(error)
                    if (error.response?.status === 429) {
                      this.$toast.error(
                        this.$t('resend-verification-email-throttled')
                      )
                    } else {
                      this.$toast.error(
                        this.$t('resend-verification-email-failed')
                      )
                    }
                  })
                  .finally(() => {
                    toastObject.goAway(0)
                  })
              },
            },
          }
        )
      }
    },
  },
}
