export function requireGroups(groups, options) {
  const { redirectTo, errorMessageKey } = {
    redirectTo: null,
    errorMessageKey: 'errors.unauthorized-page',

    ...options,
  }

  return ({ $auth, store, i18n, redirect, error }) => {
    const isAuthorized = store.getters['user/hasGroups'](groups)

    if (!isAuthorized) {
      if (redirectTo) {
        redirect(redirectTo)
      } else {
        error({
          statusCode: 403,
          message: i18n.t(errorMessageKey),
        })
      }
    }
  }
}

export function requireGroup(group, options) {
  return requireGroups([group], options)
}
