
export default {
  props: {
    authors: {
      type: Array,
      required: true,
    },

    hasBy: {
      type: Boolean,
      default: true,
    },

    hasLink: {
      type: Boolean,
      default: true,
    },

    showAFew: {
      type: Boolean,
      default: true,
    },

    mediumFontWeight: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasMultipleAuthors() {
      return this.authors?.length > 1
    },

    firstAuthor() {
      return this.authors[0]
    },
  },
}
