export const state = () => ({
  couponPlatforms: [],
})

export const actions = {
  async owned(context, type = null) {
    const { id } = this.getters['user/currentUser']
    let url = `/users/${id}/products`
    if (type) {
      url = `${url}?type=${type}`
    }
    const { data } = await this.$axios.$get(url)

    return data
  },

  async get(context, id) {
    const { data } = await this.$axios.$get(`/shop/products/${id}`)
    return data
  },

  async getCouponPlatforms({ commit }) {
    const { data } = await this.$axios.$get('/shop/coupon-platforms')

    commit('setCouponPlatforms', data)

    return data
  },
}

export const mutations = {
  setCouponPlatforms(state, couponPlatforms) {
    state.couponPlatforms = couponPlatforms
  },
}

export const getters = {}
