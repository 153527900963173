import { render, staticRenderFns } from "./CheckoutHeader.vue?vue&type=template&id=0f249c22&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BackLink: require('/vercel/path0/components/general/BackLink.vue').default,CartIndicator: require('/vercel/path0/components/shop/CartIndicator.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,HeaderContainer: require('/vercel/path0/components/navigation/HeaderContainer.vue').default})
