
export default {
  props: {
    price: {
      type: Number,
      default: null,
    },

    discount: {
      type: Number,
      default: null,
    },

    voucher: {
      type: Object,
      default: null,
    },

    discountPosition: {
      type: String,
      default: 'above',
    },
  },

  computed: {
    hasDiscount() {
      return this.discount > 0
    },

    hasVoucher() {
      return this.voucher !== null && this.voucher !== undefined
    },
  },
}
