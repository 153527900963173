const isDebugOn = process.env.NODE_ENV && process.env.NODE_ENV === 'development'

export const debug = {
  log(...args) {
    if (isDebugOn) {
      console.log(...args)
    }
  },
}

export default (_, inject) => {
  inject('debug', debug.log)
}
