
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },

    hideEditButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fullname() {
      return [this.address.first_name, this.address.last_name].join(' ')
    },
  },
}
