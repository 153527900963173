
export default {
  props: {
    country: {
      type: String,
      required: true,
    },
  },

  computed: {
    countryName() {
      return this.$t(`countries.${this.country}`)
    },
  },
}
