export function hasRouteOptionValue(route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      // Client
      return Object.values(m.components).some(
        (component) => component.options && component.options[key] === value
      )
    } else {
      // SSR
      return Object.values(m.components).some((component) =>
        Object.values(component._Ctor).some(
          (ctor) => ctor.options && ctor.options[key] === value
        )
      )
    }
  })
}

export function getRouteOption(route, key, fallback) {
  return route.matched.reduce((prev, m) => {
    if (process.client) {
      // Client
      return (
        Object.values(m.components).reduce(
          (prev, component) =>
            (component.options && component.options[key]) || prev,
          null
        ) || prev
      )
    } else {
      // SSR
      return (
        Object.values(m.components).reduce(
          (prev, component) =>
            Object.values(component._Ctor).reduce(
              (prev, ctor) => (ctor.options && ctor.options[key]) || prev,
              null
            ) || prev,
          null
        ) || prev
      )
    }
  }, fallback)
}

export default function ({ $auth, app: { router } }) {
  $auth.onRedirect((to, from) => {
    const { route: fromRoute } = router.resolve(from)

    /**
     * After login or register auth will redirect to $auth.options.redirect.home.
     * By comparing the redirect target with this option we know
     * this is a redirect cause by successful authentication.
     */
    if (to === $auth.options.redirect.home) {
      // If a redirect query param is set we remove it and redirect to that page instead.
      if (router.currentRoute.query?.redirect) {
        to = router.currentRoute.query.redirect
        // Remove redirect query from currentRoute so that it is not used in fullPathRedirect by auth.
        delete router.currentRoute.query.redirect
      } else {
        // If no redirect param is set we look for an authRedirectHome option on the current route
        // because the current route could be different from the default login page.
        // Therefore, we get the authRedirectHome option from the current route and redirect to this route instead.
        to = getRouteOption(fromRoute, 'authRedirectHome', to)
      }

      if ($auth.options.debug) {
        console.log('Auth redirect home to:', to)
      }

      return to
    }

    /**
     * The auth middleware will redirect to $auth.options.redirect.login.
     * By comparing the redirect target with this option we know
     * this is a redirect cause by the auth middleware.
     */
    if (to === $auth.options.redirect.login) {
      // We might not want to redirect to the default login page.
      // Therefore, we get the authRedirectLogin option from the current route and redirect to this route instead.
      to = getRouteOption(fromRoute, 'authRedirectLogin', to)

      if ($auth.options.debug) {
        console.log('Auth redirect login to:', to)
      }

      return to
    }
  })
}
