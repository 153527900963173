import { render, staticRenderFns } from "./DesktopMainNavigation.vue?vue&type=template&id=0df3a245&"
import script from "./DesktopMainNavigation.vue?vue&type=script&lang=js&"
export * from "./DesktopMainNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopoverButton: require('/vercel/path0/components/popover/PopoverButton.vue').default,DesktopMainSubnavigationWithIcons: require('/vercel/path0/components/navigation/DesktopMainSubnavigationWithIcons.vue').default,DesktopMainSubnavigation: require('/vercel/path0/components/navigation/DesktopMainSubnavigation.vue').default,PopoverPanel: require('/vercel/path0/components/popover/PopoverPanel.vue').default,Popover: require('/vercel/path0/components/popover/Popover.vue').default})
