export const state = () => ({
  client: [],
  partnerBrandings: [],
})

export const actions = {
  async loadPartnerBrandings({ commit }) {
    const { data } = await this.$axios.$get(`/partners/brandings`)

    commit('setPartnerBrandings', data)

    return data
  },

  async getAllClients() {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$get(`/users/${id}/oauth-clients`)
    return data
  },

  async getClient({ commit }, clientId) {
    const { id } = this.getters['user/currentUser']

    const { data } = await this.$axios.$get(
      `/users/${id}/oauth-clients/${clientId}`
    )
    commit('setClient', data)
    return data
  },

  async createClient({ dispatch }, client) {
    const { id } = this.getters['user/currentUser']

    const { data } = await this.$axios.$post(
      `/users/${id}/oauth-clients`,
      client
    )

    dispatch('getAllClients')

    return data
  },

  async updateClient({ dispatch }, client) {
    const { id } = this.getters['user/currentUser']

    const { data } = await this.$axios.$put(
      `/users/${id}/oauth-clients/${client.id}`,
      client
    )

    dispatch('getAllClients')

    return data
  },

  async deleteClient({ dispatch }, client) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$delete(
      `/users/${id}/oauth-clients/${client.id}`
    )
    return data
  },
}

export const mutations = {
  setClient(state, client) {
    state.client = client
  },

  setPartnerBrandings(state, partnerBrandings) {
    state.partnerBrandings = partnerBrandings
  },
}

export const getters = {}
