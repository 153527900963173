
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },
  },
}
