export const state = () => ({
  address: null,
})

export const actions = {
  async all({ commit }) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$get(`/users/${id}/addresses`)
    return data
  },

  async get({ commit }, addressId) {
    const { id } = this.getters['user/currentUser']

    const { data } = await this.$axios.$get(
      `/users/${id}/addresses/${addressId}`
    )
    commit('setAddress', data)
    return data
  },

  async create({ commit }, address) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$post(`/users/${id}/addresses`, address)
    return data
  },

  async update({ commit }, { addressId, address }) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$put(
      `/users/${id}/addresses/${addressId}`,
      address
    )
    return data
  },

  async delete({ commit }, addressId) {
    const { id } = this.getters['user/currentUser']
    const { data } = await this.$axios.$delete(
      `/users/${id}/addresses/${addressId}`
    )
    return data
  },
}

export const mutations = {
  setAddress(state, address) {
    state.address = address
  },
}

export const getters = {}
