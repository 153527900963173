
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {},
  layout({ store }) {
    if (!store?.state?.global?.navs || isEmpty(store.state.global.navs)) {
      return 'empty'
    }

    return 'default'
  },

  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      scrolled: false,
    }
  },

  beforeMount() {
    this.scrollHandler()
    window.addEventListener('scroll', this.scrollHandler)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  },

  methods: {
    scrollHandler: throttle(function () {
      requestAnimationFrame(() => {
        this.scrolled = window.pageYOffset > 100
      })
    }, 100),
  },
}
