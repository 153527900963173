import Vue from 'vue'

Vue.filter('currencyChf', (value) => {
  if (typeof value !== 'number') return ''
  return (
    'CHF ' +
    value.toLocaleString('de-CH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  )
})

Vue.filter('currencyWithoutChf', (value) => {
  if (typeof value !== 'number') return ''
  return value.toLocaleString('de-CH', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
})
