import algoliasearch from 'algoliasearch/lite'

export const state = () => ({
  productResults: [],
  pageResults: [],
  query: '',
})

export const actions = {
  async searchIndexes({ commit }, searchQuery) {
    commit('setQuery', searchQuery)

    const searchClient = algoliasearch(
      this.$config.algoliaAppId,
      this.$config.algoliaApiKey
    )

    if (!searchQuery) {
      commit('setProductResults', [])
      commit('setPageResults', [])
      return
    }

    const queries = [
      {
        indexName: this.$config.algoliaProductIndex,
        query: searchQuery,
        params: {
          hitsPerPage: 50,
          filters: `collection:products`,
        },
      },
      {
        indexName: this.$config.algoliaWebsiteIndex,
        query: searchQuery,
        params: {
          hitsPerPage: 50,
        },
      },
    ]

    const {
      results: [products, pages],
    } = await searchClient.multipleQueries(queries)

    commit('setProductResults', products.hits)
    commit('setPageResults', pages.hits)
  },
}

export const mutations = {
  setProductResults(state, value) {
    state.productResults = value
  },

  setPageResults(state, value) {
    state.pageResults = value
  },

  setQuery(state, value) {
    state.query = value
  },
}

export const getters = {
  productResults: (state) => state.productResults,
  pageResults: (state) => state.pageResults,
  query: (state) => state.query,
}
