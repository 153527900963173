export const ApplePay = () => import('../../components/icons/payments/ApplePay.vue' /* webpackChunkName: "components/apple-pay" */).then(c => wrapFunctional(c.default || c))
export const GooglePay = () => import('../../components/icons/payments/GooglePay.vue' /* webpackChunkName: "components/google-pay" */).then(c => wrapFunctional(c.default || c))
export const Invoice = () => import('../../components/icons/payments/Invoice.vue' /* webpackChunkName: "components/invoice" */).then(c => wrapFunctional(c.default || c))
export const Maestro = () => import('../../components/icons/payments/Maestro.vue' /* webpackChunkName: "components/maestro" */).then(c => wrapFunctional(c.default || c))
export const MasterCard = () => import('../../components/icons/payments/MasterCard.vue' /* webpackChunkName: "components/master-card" */).then(c => wrapFunctional(c.default || c))
export const PayPal = () => import('../../components/icons/payments/PayPal.vue' /* webpackChunkName: "components/pay-pal" */).then(c => wrapFunctional(c.default || c))
export const PostCard = () => import('../../components/icons/payments/PostCard.vue' /* webpackChunkName: "components/post-card" */).then(c => wrapFunctional(c.default || c))
export const PostFinance = () => import('../../components/icons/payments/PostFinance.vue' /* webpackChunkName: "components/post-finance" */).then(c => wrapFunctional(c.default || c))
export const Twint = () => import('../../components/icons/payments/Twint.vue' /* webpackChunkName: "components/twint" */).then(c => wrapFunctional(c.default || c))
export const Visa = () => import('../../components/icons/payments/Visa.vue' /* webpackChunkName: "components/visa" */).then(c => wrapFunctional(c.default || c))
export const Beook = () => import('../../components/icons/navigation/Beook.vue' /* webpackChunkName: "components/beook" */).then(c => wrapFunctional(c.default || c))
export const Ebooks = () => import('../../components/icons/navigation/Ebooks.vue' /* webpackChunkName: "components/ebooks" */).then(c => wrapFunctional(c.default || c))
export const HepPlus = () => import('../../components/icons/navigation/HepPlus.vue' /* webpackChunkName: "components/hep-plus" */).then(c => wrapFunctional(c.default || c))
export const MyDetailhandel = () => import('../../components/icons/navigation/MyDetailhandel.vue' /* webpackChunkName: "components/my-detailhandel" */).then(c => wrapFunctional(c.default || c))
export const MyKv = () => import('../../components/icons/navigation/MyKv.vue' /* webpackChunkName: "components/my-kv" */).then(c => wrapFunctional(c.default || c))
export const MySkillBox = () => import('../../components/icons/navigation/MySkillBox.vue' /* webpackChunkName: "components/my-skill-box" */).then(c => wrapFunctional(c.default || c))
export const Others = () => import('../../components/icons/navigation/Others.vue' /* webpackChunkName: "components/others" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/general/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AppImage = () => import('../../components/general/AppImage.vue' /* webpackChunkName: "components/app-image" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/general/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const ArrowButton = () => import('../../components/general/ArrowButton.vue' /* webpackChunkName: "components/arrow-button" */).then(c => wrapFunctional(c.default || c))
export const ArrowLink = () => import('../../components/general/ArrowLink.vue' /* webpackChunkName: "components/arrow-link" */).then(c => wrapFunctional(c.default || c))
export const BackLink = () => import('../../components/general/BackLink.vue' /* webpackChunkName: "components/back-link" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/general/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/general/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const ComponentLoader = () => import('../../components/general/ComponentLoader.vue' /* webpackChunkName: "components/component-loader" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/general/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const HepLogo = () => import('../../components/general/HepLogo.vue' /* webpackChunkName: "components/hep-logo" */).then(c => wrapFunctional(c.default || c))
export const IconCollection = () => import('../../components/general/IconCollection.vue' /* webpackChunkName: "components/icon-collection" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/general/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const MouseScroll = () => import('../../components/general/MouseScroll.vue' /* webpackChunkName: "components/mouse-scroll" */).then(c => wrapFunctional(c.default || c))
export const SelectableBgColor = () => import('../../components/general/SelectableBgColor.vue' /* webpackChunkName: "components/selectable-bg-color" */).then(c => wrapFunctional(c.default || c))
export const ShortDivider = () => import('../../components/general/ShortDivider.vue' /* webpackChunkName: "components/short-divider" */).then(c => wrapFunctional(c.default || c))
export const SimplePagination = () => import('../../components/general/SimplePagination.vue' /* webpackChunkName: "components/simple-pagination" */).then(c => wrapFunctional(c.default || c))
export const TooltipBox = () => import('../../components/general/TooltipBox.vue' /* webpackChunkName: "components/tooltip-box" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/general/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const VimeoVideoPlayer = () => import('../../components/general/VimeoVideoPlayer.vue' /* webpackChunkName: "components/vimeo-video-player" */).then(c => wrapFunctional(c.default || c))
export const YoutubePlayer = () => import('../../components/general/YoutubePlayer.vue' /* webpackChunkName: "components/youtube-player" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../components/navigation/CheckoutHeader.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const DesktopMainNavigation = () => import('../../components/navigation/DesktopMainNavigation.vue' /* webpackChunkName: "components/desktop-main-navigation" */).then(c => wrapFunctional(c.default || c))
export const DesktopMainSubnavigation = () => import('../../components/navigation/DesktopMainSubnavigation.vue' /* webpackChunkName: "components/desktop-main-subnavigation" */).then(c => wrapFunctional(c.default || c))
export const DesktopMainSubnavigationWithIcons = () => import('../../components/navigation/DesktopMainSubnavigationWithIcons.vue' /* webpackChunkName: "components/desktop-main-subnavigation-with-icons" */).then(c => wrapFunctional(c.default || c))
export const DesktopMetaNavigation = () => import('../../components/navigation/DesktopMetaNavigation.vue' /* webpackChunkName: "components/desktop-meta-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderContainer = () => import('../../components/navigation/HeaderContainer.vue' /* webpackChunkName: "components/header-container" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../components/navigation/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigation = () => import('../../components/navigation/MobileNavigation.vue' /* webpackChunkName: "components/mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigationWrapper = () => import('../../components/navigation/MobileNavigationWrapper.vue' /* webpackChunkName: "components/mobile-navigation-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MobileSearchResults = () => import('../../components/navigation/MobileSearchResults.vue' /* webpackChunkName: "components/mobile-search-results" */).then(c => wrapFunctional(c.default || c))
export const MobileSubnavigation = () => import('../../components/navigation/MobileSubnavigation.vue' /* webpackChunkName: "components/mobile-subnavigation" */).then(c => wrapFunctional(c.default || c))
export const NavLink = () => import('../../components/navigation/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c))
export const NavigationSearchInput = () => import('../../components/navigation/NavigationSearchInput.vue' /* webpackChunkName: "components/navigation-search-input" */).then(c => wrapFunctional(c.default || c))
export const EmailResendButton = () => import('../../components/auth/EmailResendButton.vue' /* webpackChunkName: "components/email-resend-button" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/auth/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const RegisterForm = () => import('../../components/auth/RegisterForm.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/forms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormErrors = () => import('../../components/forms/FormErrors.vue' /* webpackChunkName: "components/form-errors" */).then(c => wrapFunctional(c.default || c))
export const InputField = () => import('../../components/forms/InputField.vue' /* webpackChunkName: "components/input-field" */).then(c => wrapFunctional(c.default || c))
export const RadioButtons = () => import('../../components/forms/RadioButtons.vue' /* webpackChunkName: "components/radio-buttons" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/forms/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const SelectLight = () => import('../../components/forms/SelectLight.vue' /* webpackChunkName: "components/select-light" */).then(c => wrapFunctional(c.default || c))
export const Textarea = () => import('../../components/forms/Textarea.vue' /* webpackChunkName: "components/textarea" */).then(c => wrapFunctional(c.default || c))
export const AccordionList = () => import('../../components/componentloader/AccordionList.vue' /* webpackChunkName: "components/accordion-list" */).then(c => wrapFunctional(c.default || c))
export const Articles = () => import('../../components/componentloader/Articles.vue' /* webpackChunkName: "components/articles" */).then(c => wrapFunctional(c.default || c))
export const Author = () => import('../../components/componentloader/Author.vue' /* webpackChunkName: "components/author" */).then(c => wrapFunctional(c.default || c))
export const Authors = () => import('../../components/componentloader/Authors.vue' /* webpackChunkName: "components/authors" */).then(c => wrapFunctional(c.default || c))
export const ComponentGroup = () => import('../../components/componentloader/ComponentGroup.vue' /* webpackChunkName: "components/component-group" */).then(c => wrapFunctional(c.default || c))
export const ConfigureAndBuy = () => import('../../components/componentloader/ConfigureAndBuy.vue' /* webpackChunkName: "components/configure-and-buy" */).then(c => wrapFunctional(c.default || c))
export const Consulting = () => import('../../components/componentloader/Consulting.vue' /* webpackChunkName: "components/consulting" */).then(c => wrapFunctional(c.default || c))
export const EnumerationIcon = () => import('../../components/componentloader/EnumerationIcon.vue' /* webpackChunkName: "components/enumeration-icon" */).then(c => wrapFunctional(c.default || c))
export const EnumerationPoint = () => import('../../components/componentloader/EnumerationPoint.vue' /* webpackChunkName: "components/enumeration-point" */).then(c => wrapFunctional(c.default || c))
export const FaqLinks = () => import('../../components/componentloader/FaqLinks.vue' /* webpackChunkName: "components/faq-links" */).then(c => wrapFunctional(c.default || c))
export const FlowText = () => import('../../components/componentloader/FlowText.vue' /* webpackChunkName: "components/flow-text" */).then(c => wrapFunctional(c.default || c))
export const FormBuilder = () => import('../../components/componentloader/FormBuilder.vue' /* webpackChunkName: "components/form-builder" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/componentloader/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const Jumplink = () => import('../../components/componentloader/Jumplink.vue' /* webpackChunkName: "components/jumplink" */).then(c => wrapFunctional(c.default || c))
export const LinksGrid = () => import('../../components/componentloader/LinksGrid.vue' /* webpackChunkName: "components/links-grid" */).then(c => wrapFunctional(c.default || c))
export const MaterialLinks = () => import('../../components/componentloader/MaterialLinks.vue' /* webpackChunkName: "components/material-links" */).then(c => wrapFunctional(c.default || c))
export const NewsletterForm = () => import('../../components/componentloader/NewsletterForm.vue' /* webpackChunkName: "components/newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const PageLinks = () => import('../../components/componentloader/PageLinks.vue' /* webpackChunkName: "components/page-links" */).then(c => wrapFunctional(c.default || c))
export const PageTiles = () => import('../../components/componentloader/PageTiles.vue' /* webpackChunkName: "components/page-tiles" */).then(c => wrapFunctional(c.default || c))
export const ProductFilter = () => import('../../components/componentloader/ProductFilter.vue' /* webpackChunkName: "components/product-filter" */).then(c => wrapFunctional(c.default || c))
export const Products = () => import('../../components/componentloader/Products.vue' /* webpackChunkName: "components/products" */).then(c => wrapFunctional(c.default || c))
export const ProductsWide = () => import('../../components/componentloader/ProductsWide.vue' /* webpackChunkName: "components/products-wide" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSlider = () => import('../../components/componentloader/ReviewsSlider.vue' /* webpackChunkName: "components/reviews-slider" */).then(c => wrapFunctional(c.default || c))
export const ShoppingList = () => import('../../components/componentloader/ShoppingList.vue' /* webpackChunkName: "components/shopping-list" */).then(c => wrapFunctional(c.default || c))
export const SingleImage = () => import('../../components/componentloader/SingleImage.vue' /* webpackChunkName: "components/single-image" */).then(c => wrapFunctional(c.default || c))
export const Testimonials = () => import('../../components/componentloader/Testimonials.vue' /* webpackChunkName: "components/testimonials" */).then(c => wrapFunctional(c.default || c))
export const TextWithImage = () => import('../../components/componentloader/TextWithImage.vue' /* webpackChunkName: "components/text-with-image" */).then(c => wrapFunctional(c.default || c))
export const TextWithImageAndLink = () => import('../../components/componentloader/TextWithImageAndLink.vue' /* webpackChunkName: "components/text-with-image-and-link" */).then(c => wrapFunctional(c.default || c))
export const TextWithImageAndPoint = () => import('../../components/componentloader/TextWithImageAndPoint.vue' /* webpackChunkName: "components/text-with-image-and-point" */).then(c => wrapFunctional(c.default || c))
export const TextWithLinkAndBgimage = () => import('../../components/componentloader/TextWithLinkAndBgimage.vue' /* webpackChunkName: "components/text-with-link-and-bgimage" */).then(c => wrapFunctional(c.default || c))
export const TitleWithSubtitle = () => import('../../components/componentloader/TitleWithSubtitle.vue' /* webpackChunkName: "components/title-with-subtitle" */).then(c => wrapFunctional(c.default || c))
export const TwoImages = () => import('../../components/componentloader/TwoImages.vue' /* webpackChunkName: "components/two-images" */).then(c => wrapFunctional(c.default || c))
export const VideoEmbedded = () => import('../../components/componentloader/VideoEmbedded.vue' /* webpackChunkName: "components/video-embedded" */).then(c => wrapFunctional(c.default || c))
export const AuthorCard = () => import('../../components/cards/AuthorCard.vue' /* webpackChunkName: "components/author-card" */).then(c => wrapFunctional(c.default || c))
export const FilterProductGridCard = () => import('../../components/cards/FilterProductGridCard.vue' /* webpackChunkName: "components/filter-product-grid-card" */).then(c => wrapFunctional(c.default || c))
export const FilterProductListCard = () => import('../../components/cards/FilterProductListCard.vue' /* webpackChunkName: "components/filter-product-list-card" */).then(c => wrapFunctional(c.default || c))
export const MaterialLink = () => import('../../components/cards/MaterialLink.vue' /* webpackChunkName: "components/material-link" */).then(c => wrapFunctional(c.default || c))
export const MediumProductCard = () => import('../../components/cards/MediumProductCard.vue' /* webpackChunkName: "components/medium-product-card" */).then(c => wrapFunctional(c.default || c))
export const PageLink = () => import('../../components/cards/PageLink.vue' /* webpackChunkName: "components/page-link" */).then(c => wrapFunctional(c.default || c))
export const PagesCard = () => import('../../components/cards/PagesCard.vue' /* webpackChunkName: "components/pages-card" */).then(c => wrapFunctional(c.default || c))
export const SmallProductCard = () => import('../../components/cards/SmallProductCard.vue' /* webpackChunkName: "components/small-product-card" */).then(c => wrapFunctional(c.default || c))
export const FixedProductNav = () => import('../../components/heros/FixedProductNav.vue' /* webpackChunkName: "components/fixed-product-nav" */).then(c => wrapFunctional(c.default || c))
export const NoSliderHero = () => import('../../components/heros/NoSliderHero.vue' /* webpackChunkName: "components/no-slider-hero" */).then(c => wrapFunctional(c.default || c))
export const ProductHero = () => import('../../components/heros/ProductHero.vue' /* webpackChunkName: "components/product-hero" */).then(c => wrapFunctional(c.default || c))
export const SliderHeros = () => import('../../components/heros/SliderHeros.vue' /* webpackChunkName: "components/slider-heros" */).then(c => wrapFunctional(c.default || c))
export const AddShoppingListToCart = () => import('../../components/shop/AddShoppingListToCart.vue' /* webpackChunkName: "components/add-shopping-list-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/shop/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CanAddToCart = () => import('../../components/shop/CanAddToCart.vue' /* webpackChunkName: "components/can-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CartIndicator = () => import('../../components/shop/CartIndicator.vue' /* webpackChunkName: "components/cart-indicator" */).then(c => wrapFunctional(c.default || c))
export const CartDetails = () => import('../../components/cart/CartDetails.vue' /* webpackChunkName: "components/cart-details" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItem = () => import('../../components/cart/CheckoutItem.vue' /* webpackChunkName: "components/checkout-item" */).then(c => wrapFunctional(c.default || c))
export const CouponPlatformChoice = () => import('../../components/cart/CouponPlatformChoice.vue' /* webpackChunkName: "components/coupon-platform-choice" */).then(c => wrapFunctional(c.default || c))
export const LicenceRedeemChoice = () => import('../../components/cart/LicenceRedeemChoice.vue' /* webpackChunkName: "components/licence-redeem-choice" */).then(c => wrapFunctional(c.default || c))
export const PaymentIcons = () => import('../../components/cart/PaymentIcons.vue' /* webpackChunkName: "components/payment-icons" */).then(c => wrapFunctional(c.default || c))
export const PaymentOptions = () => import('../../components/cart/PaymentOptions.vue' /* webpackChunkName: "components/payment-options" */).then(c => wrapFunctional(c.default || c))
export const QuantityInput = () => import('../../components/cart/QuantityInput.vue' /* webpackChunkName: "components/quantity-input" */).then(c => wrapFunctional(c.default || c))
export const VoucherForm = () => import('../../components/cart/VoucherForm.vue' /* webpackChunkName: "components/voucher-form" */).then(c => wrapFunctional(c.default || c))
export const VoucherInput = () => import('../../components/cart/VoucherInput.vue' /* webpackChunkName: "components/voucher-input" */).then(c => wrapFunctional(c.default || c))
export const RefinementList = () => import('../../components/search/RefinementList.vue' /* webpackChunkName: "components/refinement-list" */).then(c => wrapFunctional(c.default || c))
export const SearchPageCard = () => import('../../components/search/SearchPageCard.vue' /* webpackChunkName: "components/search-page-card" */).then(c => wrapFunctional(c.default || c))
export const SearchProductCard = () => import('../../components/search/SearchProductCard.vue' /* webpackChunkName: "components/search-product-card" */).then(c => wrapFunctional(c.default || c))
export const Date = () => import('../../components/presenter/Date.vue' /* webpackChunkName: "components/date" */).then(c => wrapFunctional(c.default || c))
export const AppStore = () => import('../../components/icons/AppStore.vue' /* webpackChunkName: "components/app-store" */).then(c => wrapFunctional(c.default || c))
export const Arrow = () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeft = () => import('../../components/icons/ArrowLeft.vue' /* webpackChunkName: "components/arrow-left" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeftLight = () => import('../../components/icons/ArrowLeftLight.vue' /* webpackChunkName: "components/arrow-left-light" */).then(c => wrapFunctional(c.default || c))
export const BookOpen = () => import('../../components/icons/BookOpen.vue' /* webpackChunkName: "components/book-open" */).then(c => wrapFunctional(c.default || c))
export const Burger = () => import('../../components/icons/Burger.vue' /* webpackChunkName: "components/burger" */).then(c => wrapFunctional(c.default || c))
export const Checkmark = () => import('../../components/icons/Checkmark.vue' /* webpackChunkName: "components/checkmark" */).then(c => wrapFunctional(c.default || c))
export const Chevron = () => import('../../components/icons/Chevron.vue' /* webpackChunkName: "components/chevron" */).then(c => wrapFunctional(c.default || c))
export const Cross = () => import('../../components/icons/Cross.vue' /* webpackChunkName: "components/cross" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../../components/icons/Download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const DownloadArrow = () => import('../../components/icons/DownloadArrow.vue' /* webpackChunkName: "components/download-arrow" */).then(c => wrapFunctional(c.default || c))
export const Envelope = () => import('../../components/icons/Envelope.vue' /* webpackChunkName: "components/envelope" */).then(c => wrapFunctional(c.default || c))
export const EnvelopeAlternative = () => import('../../components/icons/EnvelopeAlternative.vue' /* webpackChunkName: "components/envelope-alternative" */).then(c => wrapFunctional(c.default || c))
export const GooglePlay = () => import('../../components/icons/GooglePlay.vue' /* webpackChunkName: "components/google-play" */).then(c => wrapFunctional(c.default || c))
export const InfoIcon = () => import('../../components/icons/InfoIcon.vue' /* webpackChunkName: "components/info-icon" */).then(c => wrapFunctional(c.default || c))
export const Lock = () => import('../../components/icons/Lock.vue' /* webpackChunkName: "components/lock" */).then(c => wrapFunctional(c.default || c))
export const Magnifier = () => import('../../components/icons/Magnifier.vue' /* webpackChunkName: "components/magnifier" */).then(c => wrapFunctional(c.default || c))
export const MapMarker = () => import('../../components/icons/MapMarker.vue' /* webpackChunkName: "components/map-marker" */).then(c => wrapFunctional(c.default || c))
export const Phone = () => import('../../components/icons/Phone.vue' /* webpackChunkName: "components/phone" */).then(c => wrapFunctional(c.default || c))
export const Pin = () => import('../../components/icons/Pin.vue' /* webpackChunkName: "components/pin" */).then(c => wrapFunctional(c.default || c))
export const Plus = () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/plus" */).then(c => wrapFunctional(c.default || c))
export const Profile = () => import('../../components/icons/Profile.vue' /* webpackChunkName: "components/profile" */).then(c => wrapFunctional(c.default || c))
export const RotatedPin = () => import('../../components/icons/RotatedPin.vue' /* webpackChunkName: "components/rotated-pin" */).then(c => wrapFunctional(c.default || c))
export const Scrolly = () => import('../../components/icons/Scrolly.vue' /* webpackChunkName: "components/scrolly" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/icons/Share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCart = () => import('../../components/icons/ShoppingCart.vue' /* webpackChunkName: "components/shopping-cart" */).then(c => wrapFunctional(c.default || c))
export const Tick = () => import('../../components/icons/Tick.vue' /* webpackChunkName: "components/tick" */).then(c => wrapFunctional(c.default || c))
export const Times = () => import('../../components/icons/Times.vue' /* webpackChunkName: "components/times" */).then(c => wrapFunctional(c.default || c))
export const ProductSlider = () => import('../../components/recommendations/ProductSlider.vue' /* webpackChunkName: "components/product-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductSliderItem = () => import('../../components/recommendations/ProductSliderItem.vue' /* webpackChunkName: "components/product-slider-item" */).then(c => wrapFunctional(c.default || c))
export const LargeWithProductImage = () => import('../../components/pageGridTiles/LargeWithProductImage.vue' /* webpackChunkName: "components/large-with-product-image" */).then(c => wrapFunctional(c.default || c))
export const SmallWithIcon = () => import('../../components/pageGridTiles/SmallWithIcon.vue' /* webpackChunkName: "components/small-with-icon" */).then(c => wrapFunctional(c.default || c))
export const SmallWithImage = () => import('../../components/pageGridTiles/SmallWithImage.vue' /* webpackChunkName: "components/small-with-image" */).then(c => wrapFunctional(c.default || c))
export const ProductAuthors = () => import('../../components/product/ProductAuthors.vue' /* webpackChunkName: "components/product-authors" */).then(c => wrapFunctional(c.default || c))
export const ProductFormatSelector = () => import('../../components/product/ProductFormatSelector.vue' /* webpackChunkName: "components/product-format-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductThumbnail = () => import('../../components/product/ProductThumbnail.vue' /* webpackChunkName: "components/product-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const ProductThumbnailLarge = () => import('../../components/product/ProductThumbnailLarge.vue' /* webpackChunkName: "components/product-thumbnail-large" */).then(c => wrapFunctional(c.default || c))
export const ProductThumbnailLink = () => import('../../components/product/ProductThumbnailLink.vue' /* webpackChunkName: "components/product-thumbnail-link" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../components/popover/Popover.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const PopoverButton = () => import('../../components/popover/PopoverButton.vue' /* webpackChunkName: "components/popover-button" */).then(c => wrapFunctional(c.default || c))
export const PopoverGroup = () => import('../../components/popover/PopoverGroup.vue' /* webpackChunkName: "components/popover-group" */).then(c => wrapFunctional(c.default || c))
export const PopoverPanel = () => import('../../components/popover/PopoverPanel.vue' /* webpackChunkName: "components/popover-panel" */).then(c => wrapFunctional(c.default || c))
export const ArticleDetailPage = () => import('../../components/pages/ArticleDetailPage.vue' /* webpackChunkName: "components/article-detail-page" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPage = () => import('../../components/pages/ArticlesPage.vue' /* webpackChunkName: "components/articles-page" */).then(c => wrapFunctional(c.default || c))
export const ContactPage = () => import('../../components/pages/ContactPage.vue' /* webpackChunkName: "components/contact-page" */).then(c => wrapFunctional(c.default || c))
export const ContentPage = () => import('../../components/pages/ContentPage.vue' /* webpackChunkName: "components/content-page" */).then(c => wrapFunctional(c.default || c))
export const ContentPageWithoutHero = () => import('../../components/pages/ContentPageWithoutHero.vue' /* webpackChunkName: "components/content-page-without-hero" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/pages/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberPage = () => import('../../components/pages/TeamMemberPage.vue' /* webpackChunkName: "components/team-member-page" */).then(c => wrapFunctional(c.default || c))
export const TeamPage = () => import('../../components/pages/TeamPage.vue' /* webpackChunkName: "components/team-page" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberCard = () => import('../../components/team/TeamMemberCard.vue' /* webpackChunkName: "components/team-member-card" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberList = () => import('../../components/team/TeamMemberList.vue' /* webpackChunkName: "components/team-member-list" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberSearchBar = () => import('../../components/team/TeamMemberSearchBar.vue' /* webpackChunkName: "components/team-member-search-bar" */).then(c => wrapFunctional(c.default || c))
export const ContactHeader = () => import('../../components/contact/ContactHeader.vue' /* webpackChunkName: "components/contact-header" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../components/profile/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const AddressForm = () => import('../../components/profile/AddressForm.vue' /* webpackChunkName: "components/address-form" */).then(c => wrapFunctional(c.default || c))
export const Country = () => import('../../components/profile/Country.vue' /* webpackChunkName: "components/country" */).then(c => wrapFunctional(c.default || c))
export const CouponCard = () => import('../../components/profile/CouponCard.vue' /* webpackChunkName: "components/coupon-card" */).then(c => wrapFunctional(c.default || c))
export const CouponCardCoupon = () => import('../../components/profile/CouponCardCoupon.vue' /* webpackChunkName: "components/coupon-card-coupon" */).then(c => wrapFunctional(c.default || c))
export const DigitalDownload = () => import('../../components/profile/DigitalDownload.vue' /* webpackChunkName: "components/digital-download" */).then(c => wrapFunctional(c.default || c))
export const ProfileNavigation = () => import('../../components/profile/ProfileNavigation.vue' /* webpackChunkName: "components/profile-navigation" */).then(c => wrapFunctional(c.default || c))
export const ProfileNavigationDropdown = () => import('../../components/profile/ProfileNavigationDropdown.vue' /* webpackChunkName: "components/profile-navigation-dropdown" */).then(c => wrapFunctional(c.default || c))
export const RedeemCoupon = () => import('../../components/profile/RedeemCoupon.vue' /* webpackChunkName: "components/redeem-coupon" */).then(c => wrapFunctional(c.default || c))
export const ShoppingListCreateShoppingListForm = () => import('../../components/profile/shopping-list/CreateShoppingListForm.vue' /* webpackChunkName: "components/shopping-list-create-shopping-list-form" */).then(c => wrapFunctional(c.default || c))
export const ShoppingListCard = () => import('../../components/profile/shopping-list/ShoppingListCard.vue' /* webpackChunkName: "components/shopping-list-card" */).then(c => wrapFunctional(c.default || c))
export const ShoppingListProductCard = () => import('../../components/profile/shopping-list/ShoppingListProductCard.vue' /* webpackChunkName: "components/shopping-list-product-card" */).then(c => wrapFunctional(c.default || c))
export const ShoppingListsSelector = () => import('../../components/profile/shopping-list/ShoppingListsSelector.vue' /* webpackChunkName: "components/shopping-lists-selector" */).then(c => wrapFunctional(c.default || c))
export const ShoppingListTotalPrice = () => import('../../components/profile/shopping-list/TotalPrice.vue' /* webpackChunkName: "components/shopping-list-total-price" */).then(c => wrapFunctional(c.default || c))
export const MinimalOrder = () => import('../../components/orders/MinimalOrder.vue' /* webpackChunkName: "components/minimal-order" */).then(c => wrapFunctional(c.default || c))
export const OrderEntryAmount = () => import('../../components/orders/OrderEntryAmount.vue' /* webpackChunkName: "components/order-entry-amount" */).then(c => wrapFunctional(c.default || c))
export const OrderStatus = () => import('../../components/orders/OrderStatus.vue' /* webpackChunkName: "components/order-status" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/orders/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const ArticleCard = () => import('../../components/articles/ArticleCard.vue' /* webpackChunkName: "components/article-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleList = () => import('../../components/articles/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleSearchBar = () => import('../../components/articles/ArticleSearchBar.vue' /* webpackChunkName: "components/article-search-bar" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/PageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const AisSearchMenuSelect = () => import('../../components/ais-search/MenuSelect.vue' /* webpackChunkName: "components/ais-search-menu-select" */).then(c => wrapFunctional(c.default || c))
export const AisSearchPagination = () => import('../../components/ais-search/Pagination.vue' /* webpackChunkName: "components/ais-search-pagination" */).then(c => wrapFunctional(c.default || c))
export const AisSearchSortByDesktop = () => import('../../components/ais-search/SortByDesktop.vue' /* webpackChunkName: "components/ais-search-sort-by-desktop" */).then(c => wrapFunctional(c.default || c))
export const AisSearchSortByMobile = () => import('../../components/ais-search/SortByMobile.vue' /* webpackChunkName: "components/ais-search-sort-by-mobile" */).then(c => wrapFunctional(c.default || c))
export const ModalsBookmarkProductModal = () => import('../../components/modals/BookmarkProductModal.vue' /* webpackChunkName: "components/modals-bookmark-product-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCouponPlatformEdubaseHintModal = () => import('../../components/modals/CouponPlatformEdubaseHintModal.vue' /* webpackChunkName: "components/modals-coupon-platform-edubase-hint-modal" */).then(c => wrapFunctional(c.default || c))
export const PartnerApiClient = () => import('../../components/partner/ApiClient.vue' /* webpackChunkName: "components/partner-api-client" */).then(c => wrapFunctional(c.default || c))
export const PartnerApiClientForm = () => import('../../components/partner/ApiClientForm.vue' /* webpackChunkName: "components/partner-api-client-form" */).then(c => wrapFunctional(c.default || c))
export const Wizard = () => import('../../components/wizard/Wizard.vue' /* webpackChunkName: "components/wizard" */).then(c => wrapFunctional(c.default || c))
export const WizardSteps = () => import('../../components/wizard/WizardSteps.vue' /* webpackChunkName: "components/wizard-steps" */).then(c => wrapFunctional(c.default || c))
export const ModalsButtonsBookmarkProductModalButton = () => import('../../components/modals/buttons/BookmarkProductModalButton.vue' /* webpackChunkName: "components/modals-buttons-bookmark-product-modal-button" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
