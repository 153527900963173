import { render, staticRenderFns } from "./IconCollection.vue?vue&type=template&id=64afbc2e&"
import script from "./IconCollection.vue?vue&type=script&lang=js&"
export * from "./IconCollection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Beook: require('/vercel/path0/components/icons/navigation/Beook.vue').default,Ebooks: require('/vercel/path0/components/icons/navigation/Ebooks.vue').default,HepPlus: require('/vercel/path0/components/icons/navigation/HepPlus.vue').default,MySkillBox: require('/vercel/path0/components/icons/navigation/MySkillBox.vue').default,MyKv: require('/vercel/path0/components/icons/navigation/MyKv.vue').default,MyDetailhandel: require('/vercel/path0/components/icons/navigation/MyDetailhandel.vue').default,Others: require('/vercel/path0/components/icons/navigation/Others.vue').default,BookOpen: require('/vercel/path0/components/icons/BookOpen.vue').default})
